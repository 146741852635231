import { MenuResponse } from '@on3/api';
import { On3IconMenu } from '@on3/icons';
import { ServiceLogo } from '@on3/ui-lib/components/ServiceLogo/ServiceLogo';
import { useClickOutside } from '@on3/ui-lib/hooks/useClickOutside';
import { useAuth } from '@on3/ui-lib/index';
import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import { useHeader } from 'components/Header';
import Close from 'components/Svg/Close';
import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useMemo, useRef, useState } from 'react';

import styles from './MobileMenu.module.scss';

const Menu = ({
  data,
  close,
  secondary = false,
}: {
  data: MenuResponse[] | null;
  close: () => void;
  secondary?: boolean;
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useClickOutside(ref, () => close());

  const {
    themes: { isTeam, active: theme },
    logo,
    secondaryLogo,
    showOn3,
  } = useHeader();

  const { currentSite } = useSite();
  const { user } = useAuth();
  const pathname = usePathname();
  const search = useSearchParams();
  const returnto = search.get('returnto') as string;
  const hasReturnTo = !!returnto;

  const isLoginPage = pathname.includes('/login');
  const loginPathname = `${isTeam ? currentSite?.url : ''}/login/`;
  const loginQuery = useMemo(() => {
    if (hasReturnTo) {
      return `?returnto=${returnto}`;
    } else if (isLoginPage) {
      return '';
    }

    return `?returnto=${pathname}`;
  }, [hasReturnTo, isLoginPage, returnto, pathname]);
  const loginLink = `${loginPathname}${loginQuery}`;

  return (
    <>
      <div className={styles.menu} data-menu-ui="mobile-menu">
        <header className={styles.header}>
          <button className={styles.button} onClick={close} type="button">
            <Close />
          </button>
          {secondary ? secondaryLogo : logo}
        </header>
        <div className={styles.inner} ref={ref}>
          <div className={styles.top}>
            {showOn3 && (
              <Link className={styles.on3} data-theme={theme} href="/">
                <ServiceLogo type="on3" />
              </Link>
            )}
            {!user?.a && <Link href={loginLink}>Login</Link>}
          </div>
          <ul>
            {data?.map((item, index) => (
              <li className={styles.menuItem} key={`menu-${item.id}-${index}`}>
                <Link href={item?.url || '/'}>{item.title}</Link>
                {item?.children?.length && (
                  <ul className={styles.dropdown}>
                    {item.children.map((subItem) => (
                      <li key={subItem.id}>
                        <Link href={subItem?.url || '/'}>{subItem.title}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={styles.overlay} />
    </>
  );
};

export const MobileMenu = ({
  data,
  secondary = false,
}: {
  data: MenuResponse[] | null;
  secondary?: boolean;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuOpen]);

  return (
    <>
      <div className={styles.block} data-menu-ui="mobile">
        <button
          aria-label="Mobile Menu"
          className={styles.button}
          name="mobile-menu"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          type="button"
        >
          <On3IconMenu />
          <span className="sr-only">Mobile Menu</span>
        </button>
      </div>
      {isMenuOpen && (
        <Menu
          close={() => setIsMenuOpen(false)}
          data={data}
          secondary={secondary}
        />
      )}
    </>
  );
};
