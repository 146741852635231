import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';

interface ISiteLogo {
  className?: string;
  slug?: string;
  [key: string]: any;
}

export const SiteLogo = ({ className, slug, ...props }: ISiteLogo) => {
  const { currentSite } = useSite();
  // TODO: stop using this for assets. Upload to sites and use that. For our sites, I will double check once data is live. This will only be loaded for our fan sites.
  const name =
    currentSite?.organization?.knownAs ?? currentSite?.organization?.name ?? '';
  const teamName = name?.replace(/\s+/g, '-').toLocaleLowerCase();
  const imageSlug = slug || '-logo';

  // move to site key. This is a one off.
  const isKsrPlus = currentSite?.url === '/sites/kentucky-wildcats';
  const pathName = isKsrPlus ? 'kentucky-plus' : teamName;

  return (
    <img
      alt={`${teamName} logo`}
      className={className}
      src={`https://on3static.com/sites/${pathName}${imageSlug}.png?v=27`}
      title={name}
      {...props}
    />
  );
};
