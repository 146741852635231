import type { MenuResponse } from '@on3/api';
import { On3IconDownArrow } from '@on3/icons';
import clsx from 'clsx';
import { usePathname } from 'next/navigation';

import styles from './Menu.module.scss';

interface MenuProps {
  data: MenuResponse[] | null;
  expanded?: boolean;
}

export const Menu = ({ data, expanded = false }: MenuProps) => {
  const pathname = usePathname();

  const isActive = (item: MenuResponse): boolean => {
    if ([item.url, `${item.url}/`].includes(pathname)) return true;

    if (item.children && item.children.length > 0) {
      return item.children.some((child) => isActive(child));
    }

    return false;
  };

  if (!data) return null;

  return (
    <nav
      className={clsx(styles.block, {
        [styles.expanded]: expanded,
      })}
      data-menu-ui="menu"
    >
      <ul className={styles.menu}>
        {data?.map((item: MenuResponse, key) => (
          <li className={styles.menuItem} key={`${item.id}-${item.key}-${key}`}>
            <a
              aria-haspopup={item?.children?.length ? 'true' : 'false'}
              data-active={isActive(item)}
              data-parent
              href={item?.url || '/'}
            >
              {item.title}
              {item?.children?.length && (
                <On3IconDownArrow
                  className={styles.arrow}
                  height={8}
                  width={12}
                />
              )}
            </a>
            {item?.children?.length && (
              <ul className={styles.dropdown} data-menu-ui="dropdown">
                {item?.children.map((subItem, index) => (
                  <li
                    className={styles.dropdownItem}
                    key={`${subItem.id}-${subItem.key}-${index}`}
                  >
                    <a
                      data-active={isActive(subItem)}
                      href={subItem?.url || '/'}
                    >
                      {subItem.title}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};
